<template>
  <v-overlay :value="show">
    <v-card v-if="show2 == false && show3 == false" class="modif" light>
      <img id="close" src="@/assets/close.png" @click="$emit('close')" />
      <h3 class="mt-5 uppercasePopup title">
        {{ title }}
      </h3>
      <h4 class="mt-10 text-center">
        <span v-if="client.TypeClient !== undefined && client.TypeClient.typologie == 'particulier'" class="capitalize">Mr </span>
        <span v-else class="capitalize">{{ client.contact_privilegie }}</span>
        <span class="uppercasePopup"
          >{{ client ? client.nom : "" }}
          {{ client ? client.prenom : "" }}</span
        >
        <span class="capitalize">
          -
          {{
            client && client.TypeClient ? client.TypeClient.typologie : ""
          }}</span
        >
      </h4>
      <h3 class="font-weight-light">
        <p class="capitalize text-center custom-pink text-h2"> 
          {{
            $moment(
              creneau && creneau.Creneau ? creneau.Creneau.date : ""
            ).format("LL")
          }}
        </p>
        <p style="line-height: 20px" class="text-center custom-pink text-h4">
          entre
          {{
            creneau && creneau.Creneau && creneau.Creneau.heure_debut
              ? $moment(creneau.Creneau.heure_debut, "HH:mm:ss").format(
                  "HH[h]mm"
                )
              : ""
          }}
          et
          {{
            creneau && creneau.Creneau && creneau.Creneau.heure_fin
              ? $moment(creneau.Creneau.heure_fin, "HH:mm:ss").format("HH[h]mm")
              : ""
          }}
        </p>
      </h3>
 <div
        style="display: flex; flex-direction: column; align-items: center"
        
      > 
       <p  v-if="call != ''" style="line-height: 20px" class="text-center custom-pink text-h5">
      Appel du {{$moment(call).format("LLL")}} 
      </p>
      
      </div>
      <div
        style="display: flex; flex-direction: column; align-items: center"
        v-if="
          client &&
            client.TypeClient &&
            client.TypeClient.typologie == 'particulier'
        "
      >
        <v-row>
          <v-col cols="10" align="center">
            <v-btn
              style="text-decoration: underline"
              class="pa-0 pb-4 pl-3 detail-btn mt-3"
              text
              dark
              color="#af2c70"
              depressed
              link
              plain
              @click="detailsBool = !detailsBool"
              >voir le détail</v-btn
            >
          </v-col>
          <v-col v-if="detailsBool == false" class="mt-3" cols="2">
            <i
              @click="detailsBool = !detailsBool"
              class="fas fa-caret-down"
            ></i>
          </v-col>
          <v-col v-else class="mt-3" cols="2">
            <i @click="detailsBool = !detailsBool" class="fas fa-caret-up"></i>
          </v-col>
        </v-row>

        <v-row
          class="details scroll"
          v-if="detailsBool == true"
          :class="{ 'mb-4': mode == 'read' }"
        >
          <v-col align="center">  
            <v-data-table
              hide-default-footer
              hide-default-header
              :headers="headers"
              :items="panier"
              disable-pagination="true"
              dense
            >
              <template v-slot:[`item.url_img`]="{ item }">
                <img width="40" :src="item.url_img" />
              </template>

              <template v-slot:[`item.quantity`]="{ item }">
                <span>x{{ item.quantity }}</span>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </div>

      <v-row class="my-4 mb-0" v-if="mode == 'modify'">
        <v-col align="center">
          <v-btn
            align="center"
            justify="center"
            color="#af2c70"
            class="
              mt-5
              py-10
              px-16
              rounded-lg
              white--text
              modify-btn
              font-weight-bold
            "
            @click="$emit(btn.action)"
            >{{ btn.title }}</v-btn
          >
        </v-col>
      </v-row>
      <v-row class="mt-0" v-if="($route.name == 'client' || $route.name == 'collectes') && mode == 'modify'">
        <v-col>
          <v-btn
            align="center"
            justify="center"
            text
            large
            color="#333"
            class=" font-weight-bold text-h5 py-5"
            @click="show2 = true"
            >{{ btn2 }}</v-btn
          >
        </v-col>
        <v-col>
          <v-btn
            align="center"
            justify="center"
            text
            large
            color="#D61111"
            class=" font-weight-bold text-h5 py-5"
            @click="show3 = true"
            >{{ btn3 }}</v-btn
          >
        </v-col>
      </v-row>
      <v-row v-if="mode == 'modify'">
        <v-col class="text-center infor">{{ info }}</v-col>
      </v-row>
    </v-card>
    <v-card v-if="show2" class="modal_2 justify-center align-center" light>
      <img id="close" src="@/assets/close.png" @click="$emit('close')" />
      <v-row>
        <v-col cols="12">
          <h3 class="mt-5 title uppercasePopup">
            Confirmation d'annulation de rendez-vous
          </h3>
        </v-col>
        <v-col cols="2" offset="4">
          <v-btn v-on:click="validate('submit')" color="success" x-large
            >oui</v-btn
          ></v-col
        >
        <v-col cols="2" @click="show2 = false"
          ><v-btn color="error" x-large>non</v-btn></v-col
        >
      </v-row>

      <v-row>
        <v-col cols="6" offset="3">
          <validation-observer ref="form">
            <ValidationProvider
              :name="'commentaire'"
              :rules="{
                required: isCommentaireMandatory,
              }"
            >
              <v-textarea
                outlined
                placeholder="Votre commentaire"
                v-model="commentaireDelete"
                no-resize
                hide-details
              ></v-textarea>
            </ValidationProvider>
          </validation-observer>

          <div id="snack">
            <v-snackbar
              v-for="error in errors"
              :key="error"
              v-model="snackbar"
              :timeout="timeout"
              right
              rounded="pill"
              color="red darken-2"
              elevation="24"
            >
              {{ error }}
            </v-snackbar>
          </div>
        </v-col>
      </v-row>
    </v-card>

    <v-card v-if="show3" class="modal_2 justify-center align-center" light>
      <img id="close" src="@/assets/close.png" @click="$emit('close')" />
      <v-row>
        <v-col cols="12">
          <h3 class="mt-5 title uppercasePopup">
            Confirmation de suppression de collecte
          </h3>
        </v-col>
        <v-col cols="2" offset="4">
          <v-btn v-on:click="$emit('removeCollecte')" color="success" x-large
            >Oui</v-btn
          ></v-col
        >
        <v-col cols="2" @click="show3 = false"
          ><v-btn color="error" x-large>Non</v-btn></v-col
        >
      </v-row>
    </v-card>
  </v-overlay>
</template>

<script>
export default {
  data() {
    return {
      url: process.env.VUE_APP_BASE_URL,
      show2: false,
      show3: false,
      detailsBool: false,
      headers: [
        { text: "Catégorie", value: "url_img", name: "url_img" },
        { text: "Libellé du produit", value: "libelle" },
        { text: "quantité", value: "quantity" },
      ],
      products: [],
      commentaireDelete: "",
      snackbar: true,
      timeout: 3000,
      errors: [],
      commentaireRequired: false,
    };
  },

  props: {
    show: {
      type: Boolean,
      default: () => false,
    },
    title: {
      type: String,
      default: () => "",
    },
    creneau: {
      type: Object,
      default: () => {},
    },
    btn: {
      type: Object,
      default: () => {},
    },
    btn2: {
      type: String,
      default: () => "",
    },
    btn3: {
      type: String,
      default: () => "",
    },
    client: {
      type: Object,
      default: () => {},
    },
    panier: {
      type: Array,
      default: () => [],
    },
    info: {
      type: String,
      default: () => "",
    },
    isCommentaireMandatory: {
      type: Boolean,
      default: () => false,
    },
    mode: {
      type: String,
      default: () => "read",
    },
    call:{
      type:String,
      default: () => "",
    }
  },

  methods: {
    goTo(route) {
      if (this.$route.name == "client") {
        this.$router.push({
          name: route,
          params: {
            id_client: this.$route.params.id_client,
            id_collecte: this.client.nextCollect[0].id_collecte,
          },
        });
      }
    },
    validate: function(event) {
      // var diffHours = this.$moment(this.creneau.date).diff(this.$moment(), 'hours')
      //  if(diffHours<48){
      //     this.commentaireRequired = true
      //   }
      if (event == "submit") {
        this.$refs.form.validate().then((valid) => {
          if (valid) {
            // ici le emit avec le commentaire je n'arrive pas à le faire
            this.show2 = false;
            this.$emit("deleteColl", this.commentaireDelete);
          } else {
            //display error
            console.log(valid);
            this.errors = [];
            let e = this.$refs.form.errors;
            for (const field in e) {
              e[field].forEach((msg) => {
                msg = msg.includes("invalide")
                  ? msg.split("est")[0] + "doit être un entier ou un décimal"
                  : msg;
                this.errors.push(msg);
              });

              this.snackbar = true;
            }
          }
        });
      }
    },

  },

  created() {
    // var diffHours = this.$moment(this.creneau.date).diff(
    //   this.$moment(),
    //   "hours"  
    // );
    // if (diffHours < 48) {
    //   this.commentaireRequired = true;
    // }
    // console.log("oui la ", this.$store.state.collecteCollectibles);
    this.$store.state.collecteCollectibles.forEach((coll) => {
      // console.log("oui la 2 ", coll.libelle);
      coll["url_img"] = this.url + coll.Categorie.url_logo;
      this.products.push(coll);
      // console.log("oui la 3 ", this.products);
    });
  },
};
</script>

<style scoped>
.v-card {
  width: 58.71vw;
  max-width: 1088px;
  height: auto;
  max-height: 800px;
  position: relative;
}
#close {
  position: absolute;
  right: -15px;
  top: -35px;
  max-width: 65px;
}

.title {
  font-size: 0.875rem;
  font-weight: bold;
  color: #333;
  display: flex;
  justify-content: center;
}

h4 {
  font-size: 2.1rem;
  font-weight: bold;
}

.custom-pink {
  color: #af2c70;
}

.uppercasePopup {
  text-transform: uppercase;
}
.carre_img_1 {
  border: 1px solid;
  border-radius: 5px;
  border-color: #e0e0e0;
  margin-right: 10px;
  margin-top: 10px;
  height: 100%;
}

.modal_2 {
  width: 58.71vw;
  max-width: 1088px;
  height: auto;
  border-radius: 11px;
}

#route
  > div
  > div.row.justify-center
  > div.fullheight.col.col-3
  > div.nextCollect.v-card.v-sheet.theme--light
  > div.v-overlay.v-overlay--active.theme--dark
  > div.v-overlay__content
  > div
  > button {
  font-size: 1.5rem;
  padding: 0 170px;
}

.details {
  width: 45%;
}

::v-deep tbody tr:nth-child(2n) {
  background-color: #f8f8f8;
}
.infor {
  font-size: 2rem;
}

.scroll {
  /* height: auto; */
  /* min-height: 46vh; */
  max-height: 15vh;
  overflow: scroll;
  overflow-y: auto;
  overflow-x: hidden;
}
::v-deep .v-btn:not(.v-btn--round).v-size--large{
  height:60px;
}
.text-h5{
  font-size: 0.8rem;
  margin-top: 20px;
}
</style>
